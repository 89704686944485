import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // Added useNavigate

const getEndpoints = [
  'https://n8n.centy.so/webhook/847a09f7-f7ff-4087-bb66-c753ec4af5c3',
  'https://n8n.centy.so/webhook/c03bb5c3-031c-400e-9c9a-2cc96f538cfc',
  'https://n8n.centy.so/webhook/2c2a3969-b756-4158-be47-f4d65227696e',
  'https://n8n.centy.so/webhook/5a904459-2e15-4fc5-8de8-0ee846b25d4b',
];

const DataPage = () => {
  const { sessionId } = useParams();
  const navigate = useNavigate(); // Hook for navigation
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAccidentWithinPolicy, setIsAccidentWithinPolicy] = useState(null); // State for policy validation
  const [vinMatch, setVinMatch] = useState(null); // State for VIN check

  const fetchData = useCallback(async () => {
    if (!sessionId) return;

    try {
      const fetchPromises = getEndpoints.map(async (endpoint) => {
        try {
          const response = await axios.get(`${endpoint}?session_id=${sessionId}`);
          return response.data;
        } catch (error) {
          console.error(`Error fetching data from ${endpoint}:`, error);
          return null;
        }
      });

      const allData = await Promise.all(fetchPromises);
      const nonEmptyData = allData.filter((data) => data !== null);

      if (nonEmptyData.length > 0) {
        setFetchedData(nonEmptyData);
        setLoading(false);

        // Accident and Policy Info Check
        const policyInfo = nonEmptyData.find((item) => item.policy_number);
        const accidentInfo = nonEmptyData.find((item) => item.accident_date);
        const vehicleInfo = nonEmptyData.find((item) => item.vehicle_vin);
        const immatriculationInfo = nonEmptyData.find((item) => item.vehicle_vin);

        if (policyInfo && accidentInfo) {
          const effectiveDate = new Date(policyInfo.effective_date);
          const expiryDate = new Date(policyInfo.expiry_date);
          const accidentDate = new Date(accidentInfo.accident_date);

          if (accidentDate >= effectiveDate && accidentDate <= expiryDate) {
            setIsAccidentWithinPolicy(true);
          } else {
            setIsAccidentWithinPolicy(false);
          }
        }

        // VIN Match Check
        if (vehicleInfo && immatriculationInfo) {
          setVinMatch(vehicleInfo.vehicle_vin === immatriculationInfo.immatriculation_vin);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [sessionId]);

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(() => {
      if (loading) {
        fetchData();
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, [fetchData, loading]);

  if (loading) {
    return <p className='text-center'>Loading...</p>;
  }

  const personalInfo = fetchedData.find(
    (item) => item.first_name && item.last_name && item.date_of_birth
  );
  const policyInfo = fetchedData.find((item) => item.policy_number);
  const vehicleInfo = fetchedData.find(
    (item) => item.vehicle_vin && item.car_make && item.car_model
  );
  const accidentInfo = fetchedData.find((item) => item.accident_date && item.items);

  return (
    <div className='p-4 max-w-[90%] md:max-w-[80%] lg:max-w-[60%] m-auto'>
      <h2 className='text-3xl font-bold text-center mb-6'>Processed Data</h2>

      {isAccidentWithinPolicy !== null && (
        <div
          className={`text-center text-white p-4 rounded-lg ${
            isAccidentWithinPolicy ? 'bg-green-500' : 'bg-red-500'
          }`}
        >
          <p>
            Is the insurance policy valid on the day of the accident? 
            <p><strong>{isAccidentWithinPolicy ? 'YES' : 'NO - POSSIBLE FRAUD'}</strong></p>
          </p>
          {/* <p>
            <strong>Accident Date:</strong> {new Date(accidentInfo.accident_date).toLocaleDateString()}
          </p>
          <p>
            <strong>Policy Effective Date:</strong> {new Date(policyInfo.effective_date).toLocaleDateString()}
          </p>
          <p>
            <strong>Policy Expiry Date:</strong> {new Date(policyInfo.expiry_date).toLocaleDateString()}
          </p> */}
        </div>
      )}

      {vinMatch !== null && (
        <div
          className={`text-center text-white p-4 rounded-lg mt-4 ${
            vinMatch ? 'bg-green-500' : 'bg-red-500'
          }`}
        >
          <p>
            Is the VIN number on the car plate and the VIN number on the immatriculation certificate the same?
            <p><strong>{vinMatch ? 'YES' : 'NO - POSSIBLE FRAUD'}</strong></p>
          </p>
        </div>
      )}

      <table className='min-w-full bg-white shadow-md rounded-lg overflow-hidden m-auto mt-4'>
        <tbody>
          {personalInfo && (
            <>
              <tr className='bg-gray-200 text-gray-600 uppercase text-sm leading-normal'>
                <th colSpan={2} className='py-3 px-6 text-left'>Driver License</th>
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>First Name - <strong className='px-1'>{personalInfo.first_name}</strong></td>
                {/* <td className='py-3 px-6 text-left'>{personalInfo.first_name}</td> */}
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>Last Name - <strong className='px-1'>{personalInfo.last_name}</strong></td>
                {/* <td className='py-3 px-6 text-left'>{personalInfo.last_name}</td> */}
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>Date of Birth - <strong className='px-1'>{new Date(personalInfo.date_of_birth).toLocaleDateString()}</strong></td>
                {/* <td className='py-3 px-6 text-left'>
                  {new Date(personalInfo.date_of_birth).toLocaleDateString()}
                </td> */}
              </tr>
            </>
          )}
          {policyInfo && (
            <>
              <tr className='bg-gray-200 text-gray-600 uppercase text-sm leading-normal'>
                <th colSpan={2} className='py-3 px-6 text-left'>Policy Information</th>
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>Policy Number - <strong className='px-1'>{policyInfo.policy_number}</strong></td>
                {/* <td className='py-3 px-6 text-left'>{policyInfo.policy_number}</td> */}
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>Insured Name - <strong className='px-1'>{policyInfo.insured_name.replace(',', '')}</strong></td>
                {/* <td className='py-3 px-6 text-left'>{policyInfo.insured_name.replace(',', '')}</td> */}
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>Effective Date - <strong className='px-1'>{new Date(policyInfo.effective_date).toLocaleDateString()}</strong></td>
                {/* <td className='py-3 px-6 text-left'>
                  {new Date(policyInfo.effective_date).toLocaleDateString()}
                </td> */}
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>Expiry Date - <strong className='px1'>{new Date(policyInfo.expiry_date).toLocaleDateString()}</strong></td>
                {/* <td className='py-3 px-6 text-left'>
                  {new Date(policyInfo.expiry_date).toLocaleDateString()}
                </td> */}
              </tr>
            </>
          )}
          {vehicleInfo && (
            <>
              <tr className='bg-gray-200 text-gray-600 uppercase text-sm leading-normal'>
                <th colSpan={2} className='py-3 px-6 text-left'>Vehicle Information - <strong className='px1'></strong></th>
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>First Name - <strong className='px1'>{vehicleInfo.first_name}</strong></td>
                {/* <td className='py-3 px-6 text-left'>{vehicleInfo.first_name}</td> */}
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>Last Name - <strong className='px1'>{vehicleInfo.last_name}</strong></td>
                {/* <td className='py-3 px-6 text-left'>{vehicleInfo.last_name}</td> */}
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>Delivery Date - <strong className='px1'>{new Date(vehicleInfo.delivery_date).toLocaleDateString()}</strong></td>
                {/* <td className='py-3 px-6 text-left'>
                  {new Date(vehicleInfo.delivery_date).toLocaleDateString()}
                </td> */}
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>Expire Date - <strong className='px1'>{new Date(vehicleInfo.expire_date).toLocaleDateString()}</strong></td>
                {/* <td className='py-3 px-6 text-left'>
                  {new Date(vehicleInfo.expire_date).toLocaleDateString()}
                </td> */}
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>Car Make - <strong className='px1'>{vehicleInfo.car_make}</strong></td>
                {/* <td className='py-3 px-6 text-left'>{vehicleInfo.car_make}</td> */}
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>Car Model - <strong className='px1'>{vehicleInfo.car_model}</strong></td>
                {/* <td className='py-3 px-6 text-left'>{vehicleInfo.car_model}</td> */}
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>Vehicle VIN - <strong className='px1'>{vehicleInfo.vehicle_vin}</strong></td>
                {/* <td className='py-3 px-6 text-left'>{vehicleInfo.vehicle_vin}</td> */}
              </tr>
            </>
          )}
          {accidentInfo && (
            <>
              <tr className='bg-gray-200 text-gray-600 uppercase text-sm leading-normal'>
                <th colSpan={2} className='py-3 px-6 text-left'>Accident Information</th>
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>Accident Date - <strong className='px1'>{new Date(accidentInfo.accident_date).toLocaleDateString()}</strong></td>
                {/* <td className='py-3 px-6 text-left'>
                  {new Date(accidentInfo.accident_date).toLocaleDateString()}
                </td> */}
              </tr>
              <tr className='border-b border-gray-200'>
                <td className='py-3 px-6 text-left'>Vehicle VIN - <strong className='px1'>{accidentInfo.vehicle_vin}</strong></td>
                {/* <td className='py-3 px-6 text-left'>{accidentInfo.vehicle_vin}</td> */}
              </tr>
              {accidentInfo.items.items.map((item, index) => (
                <tr key={index} className='border-b border-gray-200'>
                  <td className='py-3 px-6 text-left'>{item.name} - <strong className='px1'>{`${item.price} $`}</strong></td>
                  {/* <td className='py-3 px-6 text-left'>{`${item.price} $`}</td> */}
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>

      {/* Navigation Buttons */}
      <div className='mt-8 flex justify-center'>
        <button
          onClick={() => navigate('/upload')}
          className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4'
        >
          Back to Upload Page
        </button>
        <button
          onClick={() => navigate('/')}
          className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'
        >
          Log Out
        </button>
      </div>

      <div className='mt-8 p-4 text-black bg-white rounded-lg shadow-lg'>
        <pre className='whitespace-pre-wrap min-w-full'>{JSON.stringify(fetchedData, null, 2)}</pre>
      </div>
    </div>
  );
};

export default DataPage;
