import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

const UploadPage = () => {
  const [files, setFiles] = useState([null, null, null, null, null]);
  const [sessionId, setSessionId] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [draggingIndex, setDraggingIndex] = useState(null); // Pentru a gestiona indexul zonei de drag
  const navigate = useNavigate();

  const endpoints = [
    'https://n8n.centy.so/webhook/a49d7cee-930b-48bb-8091-4dd8c9ce0dc0',
    'https://n8n.centy.so/webhook/e8756a2a-6a7f-452e-b217-b002bc37d242',
    'https://n8n.centy.so/webhook/d53822e5-7e60-4dd9-9581-ddf6bab52d89',
    'https://n8n.centy.so/webhook/d23ff1fa-49ab-4e35-9eef-da945ed71d5a',
    'https://n8n.centy.so/webhook/e7a19e95-a51b-4660-ba54-980f62959e6e',
  ];

  const fieldNames = [
    'Accident Report',
    'Car VIN Plate',
    'Proof of Insurance',
    'Driving License',
    'Vehicle Registration Certificate',
  ];

  useEffect(() => {
    const authenticated = localStorage.getItem('authenticated');
    if (!authenticated) {
      navigate('/'); // Redirect to login if not authenticated
    }

    const newSessionId = uuidv4();
    setSessionId(newSessionId);
  }, [navigate]);

  const handleFileChange = (index, event) => {
    const newFiles = [...files];
    newFiles[index] = event.target.files[0];
    setFiles(newFiles);
  };

  const handleDrop = (index, event) => {
    event.preventDefault();
    setDraggingIndex(null); // Resetăm stilul de drag
    const newFiles = [...files];
    const droppedFile = event.dataTransfer.files[0];
    newFiles[index] = droppedFile;
    setFiles(newFiles);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (index) => {
    setDraggingIndex(index); // Setăm zona curentă în care se face drag
  };

  const handleDragLeave = () => {
    setDraggingIndex(null); // Resetăm când fișierul este scos din zona de drag
  };

  const handleSubmit = async () => {
    setLoading(true);

    // Setăm un timp estimat pentru procesare
    const estimatedTime = 30;
    setTimeLeft(estimatedTime);

    // Timer pentru procesare
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    try {
      // Upload fișiere
      await Promise.all(files.map(async (file, index) => {
        if (file) {
          const formData = new FormData();
          formData.append('data', file);
          formData.append('session_id', sessionId);

          try {
            const response = await axios.post(endpoints[index], formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            console.log(`${fieldNames[index]} uploaded successfully:`, response.data);
          } catch (error) {
            console.error(`Error uploading ${fieldNames[index]}:`, error);
          }
        }
      }));

      // Dacă toate fișierele sunt încărcate, oprim timerul și afișăm mesajul de succes
      clearInterval(timer);
      setUploadSuccess(true);
      setLoading(false);

      setFiles([null, null, null, null, null]);
      Array.from(document.querySelectorAll('input[type="file"]')).forEach(input => {
        input.value = '';
      });

      // Așteaptă 2 secunde după afișarea mesajului de succes, apoi redirecționează utilizatorul
      setTimeout(() => {
        navigate(`/${sessionId}`);
      }, 2000);

    } catch (error) {
      // În caz de eroare, oprim timerul și resetăm starea
      clearInterval(timer);
      setLoading(false);
      setUploadSuccess(false); // Nu afișa mesajul de succes în caz de eroare
      console.error('An error occurred during the upload:', error);
    }
  };

  // Verificăm dacă toate câmpurile au fișiere
  const isSubmitDisabled = files.some(file => file === null);

  return (
    <div className='p-8 '>
      <h2 className='text-3xl font-bold text-center mb-6'>Upload Documents</h2>
      <div className='space-y-3 flex flex-col items-center w-[80%] m-auto'>
        {files.map((file, index) => (
          <div
            key={index}
            onDrop={(event) => handleDrop(index, event)}
            onDragOver={handleDragOver}
            onDragEnter={() => handleDragEnter(index)}
            onDragLeave={handleDragLeave}
            className={`border-dashed border-2 rounded-lg p-6 shadow-md flex flex-col items-center justify-center w-[60%] transition-transform duration-300 ease-in-out ${
              draggingIndex === index ? 'border-blue-500 bg-blue-50' : 
              file ? 'bg-green-100' : 'border-gray-400 bg-white'
            } ${file ? '' : 'hover:bg-gray-100 hover:shadow-lg'}`}
          >
            <h3 className='text-xl font-semibold mb-4 text-center'>{fieldNames[index]}</h3>
            <div className='w-full flex flex-col items-center'>
              <label
                htmlFor={`file-input-${index}`}
                className='flex flex-col items-center cursor-pointer text-blue-500 hover:underline'
              >
                Drag and drop a file here to start
              </label>
              <input
                id={`file-input-${index}`}
                type="file"
                onChange={(e) => handleFileChange(index, e)}
                className='hidden'
              />
              <p className='text-gray-700 mt-2'>
                {file ? file.name : 'or select one from your computer'}
              </p>
            </div>
          </div>
        ))}
      </div>
      <button 
        onClick={handleSubmit} 
        className={`w-[30%] py-2 px-4 rounded-md mx-auto block mt-8 mb-8 transition-opacity duration-300 ease-in-out ${
          loading || isSubmitDisabled ? 'bg-blue-300 text-gray-400 cursor-not-allowed opacity-70' : 'bg-blue-500 text-white hover:bg-blue-600'
        }`}
        disabled={loading || isSubmitDisabled}
      >
        {loading ? ( 
          <div className="flex justify-center items-center">
            <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"></path>
            </svg>
            Processing...
          </div>
        ) : (
          'Submit'
        )}
      </button>
      {uploadSuccess && !loading && (
        <p className='text-blue-500 text-center mt-4 pb-4'>
          Processing completed successfully!
        </p>
      )}
      {timeLeft !== null && !uploadSuccess && (
        <p className='text-blue-500 text-center mt-4 pb-4'>
          Processing Data... {timeLeft}s
        </p>
      )}
    </div>
  );
};

export default UploadPage;
